/*Overrule leaflets required css that sets the font family for some reason*/
.leaflet-container {
  font-family: inherit !important;
}
.leaflet-popup-content {
  width: 500px !important;
}

/*End overrules*/
.popup {
  width: fit-content;
}

.popup--list-item {
  font-size: 1.6rem;
  list-style: none;
  line-height: 2.3rem;
  letter-spacing: 0.12rem;
}

.popup--divider {
  font-weight: 800;
  text-decoration: underline;
}

.popup--divider:not(:first-of-type) {
  margin-top: 0.5rem;
}

.popup--title {
  font-weight: 800;
  color: rgb(68, 62, 62);
}

.hamburger {
  width: 3rem;
  height: 3rem;
  border-radius: 2px;
  background-color: #fff;
  position: absolute;
  top: 8.5rem;
  left: 1.2rem;
  z-index: 999999;
  border: 2px solid rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger--line {
  width: 80%;
  height: 0.2rem;
  background-color: #000;
  margin: auto;
  margin-top: 0.5rem;
}

.hamburger--line:last-of-type {
  margin-bottom: 0.5rem;
}

.sidebar {
  width: 100%;
  max-width: 300px;
  height: 100%;
  background-color: #fff;
  z-index: 999999999999;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0px 4px 20px #000;
  list-style: none;
  font-size: 2rem;
}

.sidebar--close {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  border: 2px solid #000;
  z-index: 99999999999;
}

.sidebar--close:hover {
  cursor: pointer;
}

.sidebar--close .close--line {
  position: absolute;
  top: 1.2rem;
  left: 0;
  width: 100%;
  height: 0.2rem;
  background-color: #000;
  transform: rotate(45deg);
}

.sidebar--close .close--line:last-of-type {
  transform: rotate(-45deg);
}

.error--container {
  position: relative;
  top: 10rem;
  z-index: 999999999999;
}

.error--title {
  font-size: 2rem;
  background-color: rgba(0, 0, 0, 0.493);
  padding: 0.5rem;
  color: #fff;
}
