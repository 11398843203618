.search-form {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.input-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  position: relative;
}

.input-container label {
  position: absolute;
  top: 0rem;
  left: 0rem;
  font-size: 2rem;
  text-align: center;
  width: 100%;
}

.input-container input {
  border: none;
  border-bottom: 3px solid rgb(219, 219, 219);
  font-size: 1.8rem;
  padding-bottom: 0.5rem;
  font-weight: 600;
  letter-spacing: 0.5rem;
  text-align: center;
  margin-bottom: 1rem;
  outline: none;
}

.input-container input:focus ~ label,
.input-container input:not(:placeholder-shown) ~ label {
  top: -1.8rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  font-size: 1.5rem;
  transition: top 0.5s ease-in-out, font-size 0.5s ease-in-out;
}

.search-form button {
  width: 80%;
  padding: 0.5rem;
  font-size: 1.8rem;
  background-color: transparent;
  box-shadow: 2px 2px 2px #000;
}

.search-location--container {
  width: 90%;
  max-width: 950px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.card--background {
  background: linear-gradient(270deg, #001cff, #ff0000);
  background-size: 400% 400%;

  -webkit-animation: police 1s ease infinite;
  -moz-animation: police 1s ease infinite;
  animation: police 1s ease infinite;
  padding: 0.5rem;
  border-radius: 2rem;
  margin-top: 1rem;
}

.card--background:last-of-type {
  margin-bottom: 1rem;
}

.search-location--card {
  padding: 15px;
  display: flex;
  align-items: center;
  min-height: 15rem;
  background-color: #fff;
  border-radius: 2rem;
  font-size: 2rem;
}

.search-location--card:hover {
  cursor: pointer;
  background-color: rgb(219, 219, 219);
}

@-webkit-keyframes police {
  0% {
    background-color: #ff0000;
    background-position: 0% 50%;
  }
  50% {
    background-color: #001cff;
    background-position: 100% 50%;
  }
  100% {
    background-color: #ff0000;
    background-position: 0% 50%;
  }
}
@-moz-keyframes police {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes police {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (min-width: 780px) {
  .header--container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    width: 98%;
    margin: auto;
    margin-top: 2rem;
  }

  .search-form {
    width: 100%;
    grid-column: 1 / span 2;
    margin-top: 0;
  }

  .input-warning {
    grid-column: 3 / span 2;
    margin-right: 0.8rem;
  }

  .search-location--container {
    width: auto;
    display: grid;
    grid-column: 3 / span 2;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .search-location--card {
    font-size: 1.6rem;
    height: 100%;
  }

  .card--background {
    margin-top: 0;
  }

  .card--background:last-of-type {
    margin-bottom: 0;
  }
}
