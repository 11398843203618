* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;
  text-align: center;
  font-family: "Libre Franklin", sans-serif;
}

body {
  font-size: 1.6rem;
  margin: 0;
}

.center {
  margin: auto;
  width: 90%;
}

.capitalize {
  text-transform: capitalize;
}
